<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="450">
            <v-card class="px-4 pt-3">
                <v-card-title class="">
                    <span class="text-h4 font-weight-bold">试卷信息</span>
                </v-card-title>
                <div class="form-content mx-12">
                    <v-form v-model="valid" ref="form" class="">
                        <div class="form-row">
                            <v-text-field
                                label="试卷名称"
                                v-model="name"
                                required
                                :rules="[v => !!v || '请填写试卷名称']"
                            ></v-text-field>
                        </div>
                        <div class="form-row">
                            <v-select
                                v-model="type"
                                :items="typeMenu"
                                label="类型"
                                required
                                :rules="[v => !!v || '请选择类型']"
                            ></v-select>
                        </div>
                        <div class="form-row">
                            <v-select
                                v-model="level"
                                :items="levelMenu"
                                label="级别"
                                required
                                :rules="[v => !!v || '请选择级别']"
                            ></v-select>
                        </div>
                    </v-form>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit()"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, maxLength } from "vuelidate/lib/validators";

export default {
    name: "EditPaperDlg",

    mixins: [validationMixin],
    validations: {
        name: { required },
        type: { required },
        level: { required },
    },

    data: function () {
        return {
            dialog: false,

            // 数据
            paper_id: '',
            name: '',
            type: 0,
            // type_text: '',
            level: 0,
            // level_text: '',

            levelMenu: [
                { text: '一级', value: 1 },
                { text: '二级', value: 2 },
                { text: '三级', value: 3 },
                { text: '四级', value: 4 },
                { text: '五级', value: 5 },
                { text: '六级', value: 6 },
            ],
            typeMenu: [
                { text: 'Scratch', value: 1 },
                { text: 'Python',  value: 2 },
                { text: 'C/C++',   value: 3 },
                { text: 'Blockly', value: 4 },
                { text: 'EV3', value: 5 },
                { text: 'Scratch硬件', value: 6 },
                { text: 'Python硬件',  value: 7 },
            ],

            valid: true,
        };
    },
    computed: {
    },
    created() {},
    activated() {},
    methods: {
        openDlg(data = null) {
            // refs.form.reset需要在对话框初始化完成后调用，所以先设置为true
            this.dialog = true

            if (data) {
                this.paper_id = data.paper_id
                this.name = data.name
                this.type = data.type
                this.level = data.level

            } else {
                this.clear()
            }
        },
        clear() {
            this.$nextTick(() => {
                this.$refs.form.reset()
            })

            this.paper_id = ""
            this.name = ""
            this.type = 0
            this.level = 0
        },
        cancel() {
            this.clear()
            this.dialog = false
        },
        submit() {
            if (this.$refs.form.validate()) {
                // 用户确认后调用submit回调，将新的参数传递出去
                this.$emit("submit", {
                    paper_id: this.paper_id,
                    name: this.name,
                    type: this.type,
                    level: this.level,
                })
                this.dialog = false
            }
        },
    },
    components: {
    },
};
</script>

<style lang="scss" scoped>
.form-row {
    width: 100%;
    display: flex;
    align-items: center;
    &.between {
        justify-content: space-between;
    }
    &.start {
        justify-content: flex-start;
    }
    &.top {
        align-items: flex-start;
    }

    .col-one {
        // width: 380px;
        flex: 1;
        margin-right: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        &.top {
            align-items: flex-start;
        }
        &.middle {
            align-items: center;
        }
    }
    .col-two {
        flex: 1;
        margin-left: 20px;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        &.top {
            align-items: flex-start;
        }
        &.middle {
            align-items: center;
        }
    }
    .option-num {
        font-size: 50px;
        font-weight: bold;
        margin-right: 20px;
    }
    .option-other {
        flex: 1;
    }
    .option-img-wrapper {
        margin-top: 12px;
    }
    .option-img {
        display: flex;
        justify-content: flex-start;
        align-items: center;
    }

    .form-label {
        font-size: 14px;
        // color: #bbb;
        font-weight: bold;
    }
}
.operate-row {
    .value {
        width: 80px;
        max-width: 80px;
        margin-left: 20px;
        margin-right: 12px;
    }
}
.no-scroll {
    ::-webkit-scrollbar {
        width: 0px;
    }
}
</style>
