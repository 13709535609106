<template>
    <v-row justify="center">
        <v-dialog v-model="dialog" width="1050">
            <v-card class="px-4">
                <v-card-title class="mb-4">
                    <span class="text-h4 font-weight-bold mt-4">选择试题</span>
                </v-card-title>
                <div class="dlg-content">
                    <div class="option-area">
                        <div class="left">
                            <div class="option-item" style="width: 180px">
                                <v-select
                                    v-model="questionLibId"
                                    :items="questionLibMenu"
                                    color="grey lighten-2"
                                    item-text="name"
                                    item-value="value"
                                    placeholder="题库"
                                    dense
                                    outlined
                                    @change="loadQuestions"
                                ></v-select>
                            </div>
                        </div>
                        <div class="right">
                            <div class="option-item">
                                <v-select
                                    v-model="questionType"
                                    :items="questionTypes"
                                    color="grey lighten-2"
                                    item-text="name"
                                    item-value="value"
                                    placeholder="题型"
                                    dense
                                    outlined
                                    @change="loadQuestions"
                                ></v-select>
                            </div>
                            <div class="option-item">
                                <v-select
                                    v-model="type"
                                    :items="typeMenu"
                                    color="grey lighten-2"
                                    item-text="name"
                                    item-value="value"
                                    placeholder="类型"
                                    dense
                                    single-line
                                    outlined
                                    @change="loadQuestions"
                                ></v-select>
                            </div>
                            <div class="option-item">
                                <v-select
                                    v-model="level"
                                    :items="levelMenu"
                                    color="grey lighten-2"
                                    item-text="name"
                                    item-value="value"
                                    placeholder="级别"
                                    dense
                                    outlined
                                    @change="loadQuestions"
                                ></v-select>
                            </div>
                        </div>
                    </div>
                    <v-data-table
                        v-model="selected"
                        :headers="headers"
                        :items="datas"
                        item-key="qid"
                        :items-per-page="10"
                        :items-per-page-options="[5, 10, 15, -1]"
                        class="elevation-2 row-pointer"
                        show-select
                        :single-select="singleSelect"
                        :hide-default-footer="datas.length <= 10"
                        :footer-props="{
                            showFirstLastPage: true,
                            itemsPerPageText: '每页行数',
                            pageText: '',
                            'items-per-page-all-text': '全部',
                        }"
                        @click:row="selectRow"
                    >
                        <!-- <template v-slot:item.name="{ item }">
                            <div
                                class="font-weight-medium"
                            >
                                {{ item.name }}
                            </div>
                        </template>
                        <template v-slot:item.course_type="{ item }">
                            <v-chip :color="getTypeColor(item.course_type)" dark >
                                {{ item.course_type }}
                            </v-chip>
                        </template> -->
                    </v-data-table>
                </div>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="grey"
                        text
                        @click="cancel()"
                    >
                        取消
                    </v-btn>
                    <v-btn
                        class="text-h5 font-weight-medium mb-2"
                        color="green darken-1"
                        text
                        @click="submit"
                    >
                        确认
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-row>
</template>

<script>
import { getQuestionsFromLib, getQuestionLibs } from "@/api/admin_exam";
import { getTypeColor } from '@/utils/common';

export default {
    name: "SelectPaperQuestionDlg",

    data: function () {
        return {
            dialog: false,

            headers: [
                { text: "题型", value: "question_type_text", width: 80, align: "start", sortable: false },
                { text: "类型", value: "type_text", width: 80, align: "start", sortable: false },
                { text: "级别", value: "level_text", width: 80, align: "start", sortable: false },
                { text: "题目", value: "question_text", align: "start", sortable: false },
            ],
            datas: [],

            singleSelect: false,
            selected: [],

            questionLibId: "",
            questionLibMenu: [],
            questionType: "",
            questionTypes: [
                { name: "选择题", value: "1"},
                { name: "判断题", value: "2"},
                { name: "实操题", value: "3"},
            ],
            level: "",
            levelMenu: [
                { name: '一级', value: 1 },
                { name: '二级', value: 2 },
                { name: '三级', value: 3 },
                { name: '四级', value: 4 },
                { name: '五级', value: 5 },
                { name: '六级', value: 6 },
            ],
            type: "",
            typeMenu: [
                { name: 'Scratch', value: 1 },
                { name: 'Python',  value: 2 },
                { name: 'C/C++',   value: 3 },
                { name: 'Blockly', value: 4 },
                { name: 'EV3', value: 5 },
                { name: 'Scratch硬件', value: 6 },
                { name: 'Python硬件',  value: 7 },
            ],
        };
    },
    computed: {},
    created() {},
    activated() {
    },
    mounted() {
    },
    watch: {},
    methods: {
        getTypeColor,
        loadQuestionLibs() {
            // 获取当前机构的所有题库
            getQuestionLibs().then((response) => {
                let questionLibMenu = []
                for (let i in response.data) {
                    questionLibMenu.push({ name: response.data[i].name, value: response.data[i].lib_id })
                }
                this.questionLibMenu = questionLibMenu

            }).catch(function (err) {
                console.log(err)
            });
        },
        loadQuestions() {
            // 获取所有试题
            getQuestionsFromLib(this.questionLibId, this.questionType, this.type, this.level).then((response) => {
                // console.log(response.data);
                this.datas = response.data

            }).catch(function (err) {
                console.log(err);
            });
        },
        openDlg(paperId, selected = []) {
            this.paperId = paperId
            this.selected = selected

            this.dialog = true

            this.loadQuestionLibs()
            this.loadQuestions()
        },
        cancel() {
            this.dialog = false
        },
        submit() {
            // console.log(this.selected)

            this.$emit("submit", this.selected)
            this.dialog = false
        },
        selectRow(row) {
            this.selected.push(row)
        }
    },
    components: {},
};
</script>

<style lang="scss" scoped>
.dlg-content {
    width: 100%;
    padding: 0 30px;
    .option-area {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .left {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .right {
            display: flex;
            justify-content: flex-start;
            align-items: center;
        }
        .option-item {
            width: 120px;
            margin-right: 20px;
        }
    }
}
</style>

<!-- 让v-data-table行中的cursor显示pointer -->
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
