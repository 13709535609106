<template>
    <v-container fluid class="px-8" >
        <v-row>
            <v-col class="d-flex" >
                <v-btn
                    tile
                    dark
                    color="cyan darken-1"
                    class="font-weight-bold text-body-1 mb-6 mr-3"
                    @click.stop="openSelectPaperQuestionDlg()"
                >
                    <v-icon left>
                        mdi-plus-circle-outline
                    </v-icon>
                    添加试题
                </v-btn>
            </v-col>

            <v-col class="d-flex justify-end" >
                <v-btn
                    tile
                    dark
                    color="light-blue darken-1"
                    class="font-weight-bold text-body-1 mr-3"
                    @click.stop="gotoPaper()"
                >
                    <v-icon left> mdi-play </v-icon>
                    试卷讲解
                </v-btn>
            </v-col>
        </v-row>

        <!-- 试卷中的试题列表：不需要分页，单页显示即可，方便调整排序 -->
        <v-data-table
            :headers="headers"
            :items="datas"
            class="elevation-2 row-pointer"
            hide-default-footer
            disable-pagination
            @click:row="clickRow"
        >
            <template v-slot:item.index="{ item }">
                {{ item.index }}
            </template>
            <template v-slot:item.name="{ item }">
                <div class="td-line font-weight-medium">
                    <!-- <div class="active-name" @click.stop="goto(item.paper_id)" > -->
                    <div class="active-hover" @click.stop="goto(item.paper_id)" >
                        {{ item.name }}
                    </div>
                </div>
            </template>
            <template v-slot:item.operate="{ item }">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="purple"
                            @click.stop="openEditQuestionDlg(item)"
                        >
                            <v-icon dark> mdi-pencil </v-icon>
                        </v-btn>
                    </template>
                    <span>编辑试题</span>
                </v-tooltip>
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            v-bind="attrs" v-on="on"
                            class="mx-1"
                            icon
                            height="32"
                            width="32"
                            outlined
                            x-small
                            elevation="2"
                            color="red darken-1"
                            @click.stop="openDelPaperQuestionConfirmDlg(item.qid)"
                        >
                            <v-icon dark> mdi-trash-can </v-icon>
                        </v-btn>
                    </template>
                    <span>删除题目</span>
                </v-tooltip>
            </template>
        </v-data-table>

        <div class="bottom-padding"></div>

        <!-- 创建/编辑试卷 -->
        <EditPaperDlg ref="editPaperDlg" @submit="submitPaper" />
        <!-- 删除试卷 -->
        <ConfirmDlg
            ref="delPaperConfirmDlg"
            title="删除试卷"
            text="确认 删除 当前试卷么？"
            keyword=" 删除 "
            @confirm="delPaperConfirm"
        />

        <!-- 选择试题 -->
        <SelectPaperQuestionDlg ref="selectPaperQuestionDlg" @submit="submitPaperQuestion" />
        <!-- 删除试题 -->
        <ConfirmDlg
            ref="delPaperQuestionConfirmDlg"
            title="删除试题"
            text="确认 删除 当前试题么？"
            keyword=" 删除 "
            @confirm="delPaperQuestionConfirm"
        />

        <!-- 编辑选择题 对话框 -->
        <EditQuestionDlg4Choice ref="editQuestionDlg4Choice" @submit="submitQuestion" />
        <!-- 编辑判断题 对话框 -->
        <EditQuestionDlg4Judge ref="editQuestionDlg4Judge" @submit="submitQuestion" />
        <!-- 编辑判断题 对话框 -->
        <EditQuestionDlg4Operate ref="editQuestionDlg4Operate" @submit="submitQuestion" />

    </v-container>
</template>

<script>
import EditPaperDlg from '@/views/components/dialog/EditPaperDlg';
import EditQuestionDlg4Choice from '@/views/components/dialog/EditQuestionDlg4Choice';
import EditQuestionDlg4Judge from '@/views/components/dialog/EditQuestionDlg4Judge';
import EditQuestionDlg4Operate from '@/views/components/dialog/EditQuestionDlg4Operate';
import SelectPaperQuestionDlg from '@/views/components/dialog/SelectPaperQuestionDlg';
import ConfirmDlg from '@/views/components/dialog/ConfirmDlg';

import { getPaperQuestions, editPaper, delPaper, addPaperQuestion, delPaperQuestion, editQuestion } from "@/api/admin_exam";
import { getTypeColor } from '@/utils/common';
import { getTypeFirstLetter } from "@/utils/util";

export default {
    name: "PaperQuestionList",
    data() {
        return {
            headers: [
                { text: "序号", value: "index", width: 60, align: "center", sortable: false },
                { text: "题型", value: "question_type_text", width: 80, align: "start", sortable: false },
                { text: "类型", value: "type_text", width: 80, align: "start" },
                { text: "级别", value: "level_text", width: 80, align: "start", sort: (a, b) => this.compareLevel(a, b) },
                { text: "题目", value: "question_text", align: "start", sortable: false },
                { text: "操作", value: "operate", sortable: false, align: "center", width: 120 },
            ],
            datas: [],
            paperId: "",
        };
    },
    mounted() {
    },
    activated() {
        if (this.$route.query.id) {
            this.paperId = this.$route.query.id;
        }
        this.loadData();
    },
    methods: {
        getTypeColor,
        getTypeFirstLetter,
        loadData() {
            getPaperQuestions(this.paperId).then((response) => {
                // console.log(response.data);
                this.datas = response.data;
            
            }).catch(function (err) {
                console.log(err);
            });
        },
        clickRow(question) {
            this.openEditQuestionDlg(question);
        },

        // 添加试题对话框
        openSelectPaperQuestionDlg() {
            this.$refs.selectPaperQuestionDlg.openDlg(this.paperId, this.datas);
        },
        // 选择试题确认
        submitPaperQuestion(selected) {
            let qids = []
            for (let i in selected) {
                qids.push(selected[i].qid)
            }

            addPaperQuestion(this.paperId, qids).then((response) => {
                // console.log(response.data)
                if (response.msg == 'SUCCESS') {
                    this.$store.dispatch('web/SetAlert', {type: "success", msg: "添加试题成功"});

                    // 更新列表数据
                    this.loadData()

                } else {
                    this.$store.dispatch('web/SetAlert', {type: "error", msg: "添加试题失败"});
                }
            }).catch(function (err) {
                console.log(err);
            });
        },

        // 删除试题对话框
        openDelPaperQuestionConfirmDlg(qid) {
            this.$refs.delPaperQuestionConfirmDlg.openDlg(qid);
        },
        // 删除试题确认
        delPaperQuestionConfirm(qid) {
            delPaperQuestion(this.paperId, qid).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    this.loadData();
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "删除试题成功" });

                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "删除试题失败" });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },

        // 删除试卷对话框
        openDelPaperConfirmDlg(paperId) {
            this.$refs.delPaperConfirmDlg.openDlg(paperId);
        },
        // 删除试卷确认
        delPaperConfirm(paperId) {
            delPaper(paperId).then((response) => {
                // console.log(response.data)
                if (response.msg == "SUCCESS") {
                    for (let i = 0; i < this.datas.length; i++) {
                        if (this.datas[i].paper_id == paperId) {
                            this.datas.splice(i, 1)
                            break
                        }
                    }
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "删除试卷成功" });

                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "删除试卷失败" });
                }
            }).catch(function (err) {
                console.log(err);
            });
        },
        // 编辑试卷确认
        submitPaper(paper) {
            // editPaper(paper.paper_id, paper.name, paper.type, paper.level).then((response) => {
            //     // console.log(response.data)
            //     if (response.msg == 'SUCCESS') {
            //         this.$store.dispatch('web/SetAlert', {type: "success", msg: "创建试卷成功"});

            //         // 更新列表数据
            //         this.datas.unshift(response.data);

            //     } else {
            //         this.$store.dispatch('web/SetAlert', {type: "error", msg: "创建试卷失败"});
            //     }
            // }).catch(function (err) {
            //     console.log(err);
            // });
        },

        // 查看试题对话框
        openEditQuestionDlg(question = null) {
            switch (question.question_type) {
                case 1:
                    this.$refs.editQuestionDlg4Choice.openDlg(question);
                    break;
                case 2:
                    this.$refs.editQuestionDlg4Judge.openDlg(question);
                    break;
                case 3:
                    this.$refs.editQuestionDlg4Operate.openDlg(question);
                    break;
                default:
                    break;
            }
        },
        convLevel2Num(level) {
            let num = level.substring(0,1)
            switch (num) {
                case '一':
                    return 1
                case '二':
                    return 2
                case '三':
                    return 3
                case '四':
                    return 4
                case '五':
                    return 5
                case '六':
                    return 6
                default:
                    return 0
            }
        },
        compareLevel(a, b) {
            let a2 = this.convLevel2Num(a.substring(0,1))
            let b2 = this.convLevel2Num(b.substring(0,1))
            return a2 - b2
        },
        async gotoPaper() {
            this.$router.push({ path: '/exam/question', query: { paperId: this.paperId }})
        },
        // 编辑试题
        async submitQuestion(question) {
            editQuestion(this.libId, question).then((response) => {
                // console.log(response.data);
                if (response.msg == "SUCCESS") {
                    // 更新列表数据
                    let newData = response.data
                    if (question.qid == '') {
                        this.datas.unshift(newData)
                    } else {
                        for (let i in this.datas) {
                            if (this.datas[i].qid == question.qid) {
                                newData.index = this.datas[i].index
                                this.datas[i] = newData
                                this.$set(this.datas, i, this.datas[i]);
                                break
                            }
                        }
                    }
                    this.$store.dispatch("web/SetAlert", { type: "success", msg: "新增/编辑试题成功"});
                } else {
                    this.$store.dispatch("web/SetAlert", { type: "error", msg: "新增/编辑试题失败" });
                }

            }).catch(function (err) {
                console.log(err);
            });
        },
    },
    components: {
        EditPaperDlg,
        EditQuestionDlg4Choice,
        EditQuestionDlg4Judge,
        EditQuestionDlg4Operate,
        SelectPaperQuestionDlg,
        ConfirmDlg,
    }
};
</script>

<style lang="scss" scoped>
.option-area {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    .option-item {
        width: 150px;
    }
}
.btn-font {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
}

.add-btn-row {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-bottom: 20px;
}

.td-line {
    text-align: left;
    display: flex;
    justify-content: flex-start;
    height: unset !important;
    .active-name {
        padding: 8px 25px;
        border-radius: 50px;
        // background-color: #efefef;
        background-color: #fff;
        &:hover {
            cursor: pointer;
            background-color: #fff;
            border-radius: 50px;
        }
    }
    .active-hover {
        cursor: pointer;
    }
}
.bottom-padding {
    height: 35px;
    width: 100%;
}
</style>

<!-- 让v-data-table行中的cursor显示pointer -->
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
